import React from 'react'
import { Layout } from '../templates'
import SEO from '../templates/Layout/SEO'

export default () => (
  <Layout>
    <SEO
      description="See the privacy policy around using Accolade's digital tools."
      title="Digital Tool Policy"
    />
    <div>
      <div class="flex justify-center">
        <section class="flex-col py-12 w-4/5">
          <h1 class="text-4xl font-semibold pb-2 text-blue-brand">
            Accolade Digital Tools Privacy Notice
          </h1>
          <p class="my-3">Last updated: February 16, 2021</p>
          <p class="my-3">
            This “<span class="font-bold">Privacy Notice</span>” describes how
            your personal information may be collected and used by Accolade,
            Inc. and its affiliates (“<span class="font-bold">Accolade</span>”)
            related to your use of one or more digital tools made available by
            Accolade, including the Accolade Digital Tools, Accolade Portal
            (including MyAccolade), and/or any other domains associated with the
            digital tools (the “
            <span class="font-bold">Accolade Digital Tools</span>”). Please note
            that the Accolade.com website has a separate privacy notice, which
            can be found here:{' '}
            <a class="text-blue-brand underline" class="text-blue-brand underline" href="https://accolade.com/privacy-policy/">
              https://accolade.com/privacy-policy/.
            </a>
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">
            Your Acceptance of this Privacy Notice
          </h2>
          <p class="my-3">
            This Privacy Notice is intended to be read in conjunction with the Terms of Use for the Accolade Digital Tools. By using the Accolade Digital Tools, you are expressly agreeing to the terms of this Privacy Notice and consent to the collection and use of information as discussed in this Privacy Notice. If you do not consent to this Privacy Notice, please discontinue your use of the Accolade Digital Tools. {' '}
            <span class="font-bold">
              Please read this Privacy Notice carefully. Because of the nature of the type of services that Accolade offers, Accolade is required to collect, retain, and process certain of your Personal Information in order to comply with contractual and regulatory obligations.
            </span>
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">Revisions of these terms</h2>
          <p class="my-3">
            This Privacy Notice was last updated as of the date indicated above. Accolade may revise this Privacy Notice at any time. Should this Privacy Notice change materially, Accolade will indicate that a change has been made on your next use of the Accolade Digital Tools. Any revision and/or addition to this Privacy Notice shall become effective and binding on you when you continue to use the Accolade Digital Tools on or after posting of such revision and/or addition.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">No Medical or Legal Advice</h2>
          <p class="my-3">
            Accolade is here to simplify healthcare and your health benefits for you and your family. Our goal is to try to help you understand your healthcare options, to facilitate your use of the healthcare system and to support you as you make your healthcare decisions. The content we provide you is for informational purposes only, is based on the best available information, and may not be relied upon when alternative and more relevant information (such as, for example, information provided directly by your health care provider) would be more relevant or accurate. No material contained within or provided through the Accolade Digital Tools should be construed as medical advice and/or treatment. Accolade shall have no responsibility or liability for out-of-pocket costs you may incur for actual care provided or medical procedures performed by your healthcare provider(s), and you are responsible for understanding and evaluating such costs, including any costs known as “surprise billing” or costs incurred as a result of balanced billing, notwithstanding information provided by Accolade based on best available information. Accolade does not practice medicine and no physician- or nurse-to patient- relationship is created as a result of your use of the Accolade Digital Tools. You should seek the careof your healthcare professional.
          </p>
          <p class="my-3">
            IF YOU ARE EXPERIENCING A HEALTH EMERGENCY, CALL 911 OR YOUR HEALTHCARE PROFESSIONAL IMMEDIATELY.
          </p>
          <p class="my-3">
            IF AN ACCOLADE REPRESENTATIVE REASONABLY SUSPECTS THAT YOU MAY BE EXPERIENCING A HEALTH EMERGENCY, OR MAY BE A DANGER TO YOURSELF OR ANOTHER PERSON, ACCOLADE RESERVES THE RIGHT, AND YOU EXPRESSLY AGREE, THAT ACCOLADE MAY CONTACT THE APPROPRIATE EMERGENCY SERVICES, INCLUDING CALLING 911, CONTACTING CHILD PROTECTION SERVICES, A SUICIDE HOTLINE OR OTHER APPROPRIATE SERVICE.
          </p>
          <p class="my-3">
            Nothing contained or provided through the Accolade Digital Tools is intended or should be construed as legal advice or guidance. No attorney-client relationship is created between you and Accolade or its personnel. If you have any questions about any law, rule or regulation, or seek legal advice regarding your healthcare, you should contact your own legal counsel.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">What Types of Information Do We Collect</h2>
          <p class="my-3">
            We are delighted to bring you the Accolade Digital Tools, which permits you and your families the opportunity to receive the same great Accolade service that we provide to hundreds and thousands of families through our Accolade Health Assistant® representatives in the form of a Digital Tools.
            The Accolade Digital Tools will allow you to communicate with your personal Accolade Health Assistant® representative and to access certain information about your health benefits and claims. All such communications are strictly limited to and for the purpose of providing information to you about the benefits available to you under your health plan, including the services provided by Accolade. This Privacy Notice is designed to advise you of how we will access, transmit, and store your information as part of your use of the Accolade Digital Tools, how we protect such information, how your information may be used or disclosed, and what rights you have to access your information. Unless otherwise specified, this Privacy Notice is intended to describe how Accolade may handle your information collected through the Accolade Digital Tools, which in the case of Your Personal Information (defined below) will always be consistent with the Notice of Privacy Practices of your health plan.
          </p>
          <p class="my-3">
            In order to provide you with access to the Accolade Digital Tools, there is certain personal and protected health information that the Accolade Digital Tools will securely access and transmit. Much of this information is similar to the information that our Accolade Health Assistant® representatives access for you today, and includes certain personal and health related information, such as:
          </p>
          <ul class="ml-10">
            <li class="list-disc">
              general personal and demographic information (e.g., name, sex, date of birth, address, dependents)
            </li>
            <li class="list-disc">
              health plan benefits and eligibility information
            </li>
            <li class="list-disc">
              health and claims information
            </li>
            <li class="list-disc">
              certain personal device information (such as physical location, IP address, data or application usage, and accelerometer data)
            </li>
            <li class="list-disc">
              other personal or protected health information that you may provide to Accolade via the Accolade Digital Tools
            </li>
          </ul>
          <p class="my-3">
            collectively, “
            <span class="font-bold">Your Personal Information.</span>”
          </p>
          <p class="my-3">
            Beyond Your Personal Information, Accolade may collect certain other information from you when you are using the Accolade Digital Tools; for example:
          </p>
          <ul class="ml-10">
            <li class="list-disc">operating system version</li>
            <li class="list-disc">device type</li>
            <li class="list-disc">access or duration of use times</li>
            <li class="list-disc">server logs</li>
            <li class="list-disc">cookies</li>
            <li class="list-disc">
              pixel tags or beacons and other tracking tools
            </li>
            <li class="list-disc">app navigation and general analytics</li>
          </ul>
          <p class="my-3">
            collectively, “
            <span class="font-bold">General Usage Information.</span>” Your
            Personal Information does not include publicly available
            information.
          </p>
          <p class="my-3">
            General Usage Information does <span class="underline">not</span>{' '}
            include Your Personal Information (as defined above), and, in general, relates to information from which Accolade can understand how users of its Accolade Digital Tools use and interact with specific features in an aggregated manner, so that we can improve the quality of such features or remove features that are not being used. However, General Usage Information may be linked to Your Personal Information in limited circumstances. For example,
            General Usage Information may be used to provide technical support to our users. The uses are
            further described in the “HOW WE USE GENERAL USAGE INFORMATION” section below. If General Usage Information is combined with Your Personal Information, then it is treated as Your Personal
            Information and subject to HIPAA and the standards set forth in the “HOW WE USE YOUR PERSONAL INFORMATION” section below.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">
            How We Acquire or Access Your Personal and General Information
          </h2>
          <p class="my-3">
            The Accolade Digital Tools acquire or access Your Personal and General Information from the following sources:
          </p>
          <ul class="ml-10">
            <li class="list-disc">
              Directly from you and your use of the Services;
            </li>
            <li class="list-disc">Your Employer;</li>
            <li class="list-disc">Your health plan carrier;</li>
            <li class="list-disc">
              The Third-Party Administrator of Your Plan;
            </li>
            <li class="list-disc">Your Benefit Administrator;</li>
            <li class="list-disc">
              Your Benefit Point Solutions, such as Alight, Teladoc, Hinge and
              other third-party point solutions; and
            </li>
            <li class="list-disc">
              With authorization and in limited circumstances, directly from
              Your health providers.{' '}
            </li>
          </ul>
          <p class="my-3">
            When you register to use the Accolade Digital Tools, you will be asked to provide certain Personal Information, including name, date of birth, address, medical ID number, social security number or other identifying code, in order for us to verify your identity and to associate your online/mobile account with our record for you.
          </p>
          <p class="my-3">
            Once you are registered, and agree to use the Accolade Digital Tools, you are permitting Accolade to transmit and receive Your Personal Information via the Accolade Digital Tools. The Accolade Digital Tools provides functionality for you to view certain of Your Personal Information that Accolade already maintains as part of its role as a service provider to your employer and/or your health plan carrier through which you have purchased a health plan or other benefits, and for you to submit certain of Your Personal Information to Accolade to help you understand your healthcare options, facilitate your use of the healthcare system and support you as you make your healthcare decisions. The content we provide you is for informational purposes only. Accolade does not provide medical advice, provide nursing care, diagnose or deliver treatment. Those are activities for you to undertake with your doctor or other appropriately trained and qualified health professional.
          </p>
          <p class="my-3">
            When you use the Accolade Digital Tools, some information may be automatically collected. For example, your mobile device operating system, device identifier (e.g., a number that is automatically assigned to your device used to access the Accolade Digital Tools, and allows us to identify your device), access times, operating system type, device type, access times, duration of use, platform, new and repeat information, time stamp and language, and other similar information commonly used to track usage of applications on mobile devices may be logged automatically. This automatically collected information is necessary to ensure that the Accolade Digital Tools can function as intended and in conformance with this Privacy Notice.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">How We Use Your Personal Information</h2>
          <p class="my-3">
            Generally speaking, we access and use Your Personal Information only as may be necessary to provide Accolade Digital Tools to you, and to support the other services that Accolade provides to you. We will not collect additional categories of Your Personal Information or use Your Personal Information we collected for materially different, unrelated, or incompatible purposes without providing you notice. Rest assured that all of Your Personal Information will be kept private and secure. Within this context, and subject to HIPAA to the extent any of Your Personal Information constitutes “protected health information,” we may use Your Personal Information for the following purposes limited to and for the purpose of providing information to you about the benefits available to you under your health plan, including the services provided by Accolade:
          </p>
          <table class="table-auto border my-3">
            <thead>
              <tr class="border">
                <th class="px-4 border py-2">Category Name</th>
                <th class="px-4 border py-2">
                  Categories of Your Personal Information
                </th>
                <th class="px-4 py-2">Use of Personal Information</th>
              </tr>
            </thead>
            <tbody>
              <tr class="border">
                <td class="px-4 border py-2">Personal Identifiers</td>
                <td class="px-4 border py-2">
                  Name, email, phone number, health plan member ID, Date of
                  Birth (“DOB”), social security number (“SSN”), home address,
                  work address, employer
                </td>
                <td class="px-4 border py-2">
                  To authenticate you and/or your personal device for purposes
                  of application security and to help prevent fraud and data
                  loss.
                </td>
              </tr>
              <tr class="border">
                <td class="px-4 border py-2">Service Provider information</td>
                <td class="px-4 border py-2">
                  Name, phone number, address, DOB, SSN, health plan member ID,
                  claims data, prescription data, benefit eligibility
                  information, mobile messaging transcripts
                </td>
                <td class="px-4 border py-2">
                  To permit you to view some of Your Personal Information that
                  Accolade already maintains as part of its role as a service
                  provider to your employer and/or your health plan carrier
                  through which you have purchased a health plan or other
                  benefits.
                </td>
              </tr>
              <tr class="border">
                <td class="px-4 border py-2">Member support</td>
                <td class="px-4 border py-2">
                  Name, phone number, DOB, address, SSN, claims data, benefits
                  eligibility information, health plan member ID, mobile
                  messaging transcripts, internet usage information
                </td>
                <td class="px-4 border py-2">
                  For you to submit certain of Your Personal Information to
                  Accolade to help you understand your healthcare options,
                  facilitate your use of the healthcare system and support you
                  as you make your healthcare decisions;
                </td>
              </tr>
              <tr class="border">
                <td class="px-4 border py-2">Member communications</td>
                <td class="px-4 border py-2">
                  Name, address, DOB, claims data, benefits eligibility
                  information, health plan member ID
                </td>
                <td class="px-4 border py-2">
                  To deliver certain products and services to you that Accolade
                  provides or that may be available to you as part of your
                  health plan through Accolade or Accolade’s partners.
                </td>
              </tr>
              <tr class="border">
                <td class="px-4 border py-2">Member outreach</td>
                <td class="px-4 border py-2">
                  Name, address, email, phone number, claims data, benefits
                  eligibility information, health plan member ID
                </td>
                <td class="px-4 border py-2">
                  send outreach and engagement communications that make you
                  aware of your eligibility for certain benefits, or to provide
                  information about products and services that may be of
                  interest to you.
                </td>
              </tr>
              <tr class="border">
                <td class="px-4 border py-2">Internal Business Purposes</td>
                <td class="px-4 border py-2">
                  Name, address, phone number, DOB, SSN, health plan member ID,
                  claims data, prescription data, benefit eligibility
                  information, medical notes, Accolade interaction notes and
                  summaries, call recordings
                </td>
                <td class="px-4 border py-2">
                  For Accolade internal purposes, such as for quality control,
                  compliance review or for training.
                </td>
              </tr>
            </tbody>
          </table>
          <p class="my-3">
            We also may share Your Personal Information with professionals who
            are treating you or for billing or claims purposes pursuant to your
            health plan. Below, under “
            <span class="font-bold">Third Party-Disclosure</span>” is additional information regarding the sharing of Your Personal Information. Whenever we do disclose Your Personal Information to others, we only share the minimum necessary amount of information to support Accolade’s services. Otherwise, we will only share Your Personal Information with your permission, and in all cases limited to and for the purpose of providing information to you about the benefits available to you under your health plan, including the services provided by Accolade.
          </p>
          <p class="my-3">
            As noted above, where Your Personal Information also constitutes protected health information under the HIPAA, Your Personal Information will be handled in accordance with HIPAA and other applicable laws and will govern Accolade’s permitted uses and disclosures of Your Personal Information. Even where Your Personal Information may not be protected health information that is also protected by the federal HIPAA privacy law and various state laws, we treat all of Your Personal Information in a secure manner consistent with HIPAA and your health plan’s Notice of Privacy Practices. From time to time, Accolade may create and distribute de- identified health information by removing all references to personally identifiable information. Any distribution of such de- identified information is anonymous. However, we will never sell or provide Your Personal Information to any third party (even if it is de-identified) for the purpose of marketing their services to you or for any purpose unrelated to Accolade’s services consistent with the benefits available to you under your health plan.
          </p>
          <p class="my-3">
            <span class="font-bold underline">Special Note:</span> Even if you decide not to use the Accolade Digital Tools, Accolade will have access to certain of the above-referenced information in its role as a service provider to your employer and/or a health plan carrier through which you have purchased a health plan or other benefits. As an employee or member of a health plan of an Accolade customer, you may have already consented to Accolade’s access to and use of such information for these purposes. This will not change whether or not you use the Accolade Digital Tools. If you have purchased a health plan from one of
            Accolade’s health plan customers, or receive benefits through a group health insurance plan
            administered by your employer, please refer to the “Notices of Privacy Practices” available to you through such heath plan carrier (e.g., a Blue Cross Blue Shield company) or your employer with respect to how your personal information may be used and disclosed in connection with your health benefits.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">How We Use General Usage Information?</h2>
          <p class="my-3">
            We may use your General Usage Information in the following manner:
          </p>
          <table class="table-auto border my-3">
            <thead>
              <tr>
                <th class="px-4 border py-2">Category Name</th>
                <th class="px-4 border py-2">
                  Categories of General Usage Information
                </th>
                <th class="px-4 py-2">Use of Personal Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="px-4 border py-2">Internal Business Purpose</td>
                <td class="px-4 border py-2">
                  Member identifying information, message content, application
                  usage data (e.g., features/pages accessed by a member),
                  location data (GPS).
                </td>
                <td class="px-4 border py-2">
                  Operate and improve the Accolade Digital Tools and associated
                  services.
                </td>
              </tr>
              <tr>
                <td class="px-4 border py-2">Usage Information</td>
                <td class="px-4 border py-2">
                  Member identifying information, message content, application
                  usage data (e.g., features/pages accessed by a member),
                  location data (GPS).
                </td>
                <td class="px-4 border py-2">
                  Monitor and track your access to and usage of the Accolade
                  Digital Tools, and understand and analyze information related
                  to the number and type of visitors that browse and use the
                  Accolade Digital Tools.
                </td>
              </tr>
              <tr>
                <td class="px-4 border py-2">Member Support</td>
                <td class="px-4 border py-2">Name, phone number, mailing address, email address</td>
                <td class="px-4 border py-2">
                  Respond to your comments and questions and provide customer
                  and support service.
                </td>
              </tr>
              <tr>
                <td class="px-4 border py-2">Member outreach</td>
                <td class="px-4 border py-2">
                  Name, mailing address, DOB, SSN, health plan member ID, claims
                  data, prescription data, benefit eligibility information
                </td>
                <td class="px-4 border py-2">
                  Link or combine with other information we get from third
                  parties, to help understand your needs and provide you with
                  better information and services via the Accolade Digital
                  Tools.
                </td>
              </tr>
              <tr>
                <td class="px-4 border py-2">Engagement Tools</td>
                <td class="px-4 border py-2">
                  Member identifying information, message content, application
                  usage data (e.g., features/pages accessed by a member),
                  location data (GPS).
                </td>
                <td class="px-4 border py-2">
                  Personalize and customize the content, outreach, and other
                  information provided to you in relation to our services
                  through the Accolade Digital Tools.
                </td>
              </tr>
              <tr>
                <td class="px-4 border py-2">Member Communications</td>
                <td class="px-4 border py-2">Name, phone number, mailing address, email address</td>
                <td class="px-4 border py-2">
                  Contact you to provide responses to your inquiries or to
                  provide you with additional information about our products and
                  services that your health plan provides.
                </td>
              </tr>
              <tr>
                <td class="px-4 border py-2">Member outreach</td>
                <td class="px-4 border py-2">
                  Name, address, email address, phone number, benefit
                  eligibility information
                </td>
                <td class="px-4 border py-2">
                  Send you notices about and/or register you for events related
                  to your health plan benefits; and
                </td>
              </tr>
              <tr>
                <td class="px-4 border py-2">Member communications</td>
                <td class="px-4 border py-2">
                  Name, address, email address, phone number, usage information
                </td>
                <td class="px-4 border py-2">
                  Communicate with you about new opportunities to engage with
                  your Accolade Health Assistants® representatives (including
                  contests, promotions and rewards, upcoming events, and other
                  news about products and services offered by Accolade and by
                  selected partners that are part of your health plan benefits).
                </td>
              </tr>
            </tbody>
          </table>
          <p class="my-3">
            By using the Accolade Digital Tools, you are opting in to receive
            such information from Accolade, including by direct message via the
            Accolade Digital Tools or separately by email. You can opt out
            anytime from these communications as further described below.
          </p>
          <p class="font-bold my-3">Does the Site Use Cookies?</p>
          <p class="my-3">
            Yes, Accolade Digital Tools uses “cookies” and other web tracking
            tools. We may store information about you using the cookies on
            Accolade Digital Tools. A cookie also called web cookie, Internet
            cookie, browser cookie, or simply cookie) is a small piece of data
            sent from a website and stored on the user’s computer by the user’s
            web browser while the user is browsing. We use cookies to improve
            your experience, to assess content usage, to distinguish you from
            other users of Accolade Digital Tools and to support the marketing
            of our services. For example, cookies help determine what kind of
            content we will serve to the user – a use case might be, if a user
            is browsing a particular set of page content themes, we might serve
            that theme in the form of advertising as we’ve identified them as an
            interested party of that theme.
          </p>
          <p class="my-3">
            We want to be completely transparent about the cookies we use and to
            make their control as easy as possible for you. When you first enter
            Accolade Digital Tools, you will be asked to accept or reject
            cookies. If, after your initial visit to Accolade Digital Tools, you
            decide to change your cookie selection, you can do so by visiting{' '}
            <a class="text-blue-brand underline" class="text-blue-brand underline" href="https://www.accolade.com/privacy/cookies">
              https://www.accolade.com/privacy/cookies
            </a>
            .
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">Third Party-Disclosure</h2>
          <p class="my-3">
            YOUR PERSONAL INFORMATION WILL NOT BE SOLD. Nor will Your Personal Information be licensed or disclosed to unaffiliated third-parties, except in connection with the sale, transfer, merger, consolidation or other transaction involving all or part of our company or as may be permitted under HIPAA and state privacy laws.
          </p>
          <p class="my-3">
            Under HIPAA, and other state privacy laws, Accolade may be permitted to disclose protected health information to business associates of Accolade or its customers who perform various functions or provide services on their behalf. To perform these functions or to provide services, business associates will receive, create, maintain, use, or disclose protected health information that has been provided to or made available to the business associate by Accolade, but only after we require the business associates to agree in writing to contract terms designed to safeguard your information or, in the case of your employer’s or health plan carrier’s business associates, reasonable assurance that they will safeguard Your Personal Information.
          </p>
          <p class="my-3">
            In addition to the above, we may share Your Personal Information and the General Usage Information collected from you, as described above, with third party partners that provide services as part of your health plan benefits to enable us to analyze and use such information in the manner set forth in this Privacy Notice. We also may provide certain information to our partners who assist us in operating the Accolade Digital Tools, conducting our business, or serving our clients, so long as those parties agree to keep this information confidential and comply with applicable law. We only share Your Personal Information and the General Usage Information for the purpose of providing services to you as part of the benefits available to you under your health plan, including to send outreach and engagement communications that make you aware of your eligibility for certain benefits, or to provide information about products and services that may be of interest to you.
          </p>
          <p class="my-3">
            WE DO NOT SELL OR TRANSFER YOUR PERSONAL INFORMATION OR THE GENERAL USAGE INFORMATION TO THIRD PARTIES FOR MARKETING PURPOSES.
          </p>
          <p class="my-3">
            In addition to the above, you acknowledge that we may be required to disclose your information to (i) comply with laws or to respond to lawful requests and legal process, (ii) to investigate, prevent, or take action regarding illegal or suspected illegal activities, (iii) to protect the rights and property of Accolade, and others to enforce our agreements, and this Privacy Notice and Terms of Use, (iv) in an emergency to protect person or property, (v) as may be needed on a confidential basis to support a corporate transaction (including a merger, acquisition, divestiture, financing, or sale of assets or lines of business, or (vi) on an aggregated basis for any purpose in which your specific personal information is blinded, masked or otherwise not identifiable.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">How We Protect Your Information?</h2>
          <p class="my-3">
            The security, integrity, and confidentiality of your information are extremely important to us. We have implemented technical, administrative and physical security measures that are designed to protect your information from unauthorized access, disclosure, use and modification. We comply with applicable law and follow industry standard practices to protect and secure and maintain the integrity and availability of your information.
          </p>
          <p class="my-3">
            Such protections include strong encryption of Your Personal Information when in-transit across public networks. The Accolade Digital Tools Site and our internal networks supporting the Accolade Digital Tools are scanned regularly for malware and other viruses. Accolade monitors the Accolade Digital Tools for any security incidents that could compromise your information.
          </p>
          <p class="my-3">
            Notwithstanding the foregoing, you acknowledge that no security safeguards or method of transmission over the Internet are completely secure. Accolade assesses its security procedures regularly and will make updates and enhancements in order to better protect your information. We ask that your take similar appropriate measures to protect their information, including by securing your access credentials (e.g., user name and password), not permitting your mobile device to be used by others, and by exiting from the Accolade Digital Tools after each use to ensure no information is available after you end a session on the Accolade Digital Tools. Accolade will not be liable for any losses or damages arising from your failure to comply with this Provision.
          </p>
          <p class="my-3">
            If you believe that your access information has been compromised or become aware of any other security incident involving the Accolade Digital Tools or your account, you agree to immediately notify Accolade at security@accolade.com.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">Third Party Information / Links</h2>
          <p class="my-3">
            Whether via your Accolade Health Assistant® representative or through the Accolade Digital Tools, Accolade may provide links to other third-party websites that are not under our control. Accolade is not responsible for the content found on any third-party sites. We provide these links for your convenience and for information purposes only.
          </p>
          <p class="my-3">
            Following links to or from any other sites or pages shall be at your own risk and Accolade accepts no responsibility or liability, to the extent permitted by any law, for any losses or penalties whatsoever that may be incurred as a result of any linking to any location on any linked sites or pages.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">Advertising</h2>
          <p class="my-3">
            Accolade does not permit third parties, other than your employer or health plan carrier, to advertise on the Accolade Digital Tools.
          </p>
          <p class="my-3">
            Accolade may use Your General Usage Information to serve online advertisements to you on non- Accolade websites based on your usage of the Accolade Digital Tools in order to better inform you about the Accolade Service, benefits available to you under your health plan, and the tools available to you to engage with your Accolade Health Assistant® representatives, such as the Accolade Digital Tools. You can control Accolade’s use of Your General Usage Information through your Cookie Preferences, available at {' '}
            <a class="text-blue-brand underline" href="https://www.accolade.com/privacy/cookies">
              https://www.accolade.com/privacy/cookies.
            </a>
          </p>
          <p class="my-3">
            Accolade does not engage in direct interest-based advertising to children as defined under the Children’s Online Privacy Protection Act, or where Accolade has actual knowledge that a user is under 13 years of age.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">Not Intended For Persons Under Age 18</h2>
          <p class="my-3">
            The Accolade Digital Tools are not intended for use by persons under the age 18. Parents and other guardians or custodians may be granted access, subject to applicable law and appropriate verification, of information concerning their minor children. Adults should not assist minors in accessing the Accolade Digital Tools. When visitors enter personal information into the Accolade Digital Tools, the user is representing that he/she is an adult over 18 years old.
          </p>
          <p class="my-3">
            If registration is required for any services on the Accolade Digital Tools, and a user submits information through such a registration process that indicates that such user is a child, the child’s parent or guardian will be alerted to the child’s registration and will be provided instructions on how the parent or guardian can delete the child’s registration, if so desired. To the extent that you or any user is an emancipated minor, Accolade reserves the right to request additional information from a court of relevant jurisdiction that the user is in fact an emancipated minor.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">
            When It Comes To Your Health Information, You have Certain Rights
          </h2>
          <p class="my-3">
            To the extent Accolade has such information, you can ask to see, access, or get an electronic or paper copy of your medical record and certain other health information we have about you. Ask us how to do this; we may charge a reasonable, cost-based fee for the compilation as permitted by applicable law.
          </p>
          <p class="my-3">
            You can ask us to limit what health information we use or share. (
            <span class="underline">Important Note</span>: We are not required to agree to your request under certain circumstances, which we will explain if asked, including if your request is unreasonable or if we are not entitled to accept such request as in when we are a service provider to your health plan).
          </p>
          <p class="my-3">
            You can ask us to contact you in a specific way (for example, home or office phone) or in a specific location. For example, if you would prefer that we only contact you through the Accolade Digital Tools, we will note that in your record. (
            <span class="underline">Important Note</span>: We are not required to agree to your request under certain circumstances, which we will explain if asked, including if your request is unreasonable or if we are not entitled to accept such request as in when we are a service provider to your health plan).
          </p>
          <p class="my-3">
            You can get a list of those with whom we’ve shared information (for the six years prior to your request); we will charge a reasonable, cost-based fee for the compilation as permitted by applicable law.
          </p>
          <p class="my-3">
            You can choose someone to act for you as your personal representative. We may ask you to designate such person in writing using a form of our choice.
          </p>
          <p class="my-3">
            You can file a complaint if you feel your rights are violated. You can contact Accolade’s Privacy Officer at the address below, or file a complaint with the US Department of Health and Human Services, Office for Civil Rights, by sending a letter to 200 Independence Avenue, S.W., Washington,
            D.C. 20201, calling 1-877-696-6775 or visiting{' '}
            <a class="text-blue-brand underline" href="www.hhs.gov/ocr/privacy/hipaa/complaints">
              www.hhs.gov/ocr/privacy/hipaa/complaints
            </a>
            . We will not retaliate against you for filing a complaint.
          </p>
          <p class="my-3">You can get a paper copy of this notice on request.</p>
          <p class="my-3">
            You have the right to correct any personal or medical information about you maintained or collected by Accolade; just ask us how to do this.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">California Residents</h2>
          <p class="my-3">
            The California Consumer Privacy Act of 2018 (“CCPA”) and other
            California privacy laws provides California residents (i.e.,
            consumers) with specific rights regarding personal information. This
            section of the Privacy Notice describes those CCPA rights and how to
            exercise those rights.{' '}
          </p>
          <p class="my-3">
            This section does not apply to publicly available information or to
            protected health information that is collected and governed by the
            privacy, security, and breach notification rules issued by the
            federal Department of Health and Human Services, Parts 160 and 164
            of Title 45 of the Code of Federal Regulations, established pursuant
            to the Health Insurance Portability and Availability Act of 1996
            (“HIPAA”).
          </p>
          <p class="underline">
            Access to Specific Information and Data Portability Rights
          </p>
          <p class="my-3">
            As a California resident, you have the right to request that
            Accolade disclose certain information to you about Accolade’s
            collection and use of Your Personal Information, other than your
            protected health information disclosed to us as part of your
            participation in an employer-sponsored group health plan and/or your
            health plan carrier through which you have purchased a health plan
            or other benefits, over the past twelve (12) months. Once Accolade
            receives and confirms a verifiable consumer request from you,
            Accolade will disclose to you, to the extent permitted by law:
          </p>
          <ul class="ml-10">
            <li class="list-disc">
              The categories of personal information Accolade collected about
              you.
            </li>
            <li class="list-disc">
              The categories of sources for Your Personal Information Accolade
              collected about you.
            </li>
            <li class="list-disc">
              Accolade’s business or commercial purpose for collecting Your
              Personal Information.{' '}
            </li>
            <li class="list-disc">
              The categories of third parties with whom Accolade shares Your
              Personal Information.
            </li>
            <li class="list-disc">
              If we disclosed your personal information for a business purpose,
              the personal information categories that each category of
              recipient obtained.
            </li>
          </ul>
          <p class="my-3">
            You shall have the right to request that the information described
            above be provided to you in a portable and readily useable format,
            to the extent technically feasible (“data portability”).
          </p>
          <p class="my-3">Accolade does not sell Your Personal Information. </p>
          <p class="underline italic">Deletion Request Rights</p>
          <p class="my-3">
            You have the right to request that Accolade delete certain of Your
            Personal Information that Accolade collected from and/or related to
            you and retained, subject to certain exceptions. Because of the
            relationship between Accolade and your employer and/or your health
            plan carrier, Accolade cannot delete Your Personal Information
            provided by your employer, your health care provider and/or other
            entities that support your health benefits plan.{' '}
          </p>
          <p class="my-3">
            To the extent that Accolade can delete Your Personal information,
            once Accolade receives and confirms your verifiable consumer
            request, Accolade will delete (and direct our service providers to
            delete) Your Personal Information, unless an exception applies.
          </p>
          <p class="my-3">
            We may deny your deletion request if retaining the information is
            necessary for us or our service providers to:
          </p>
          <ul class="ml-10">
            <li class="list-disc">
              Complete the transaction for which we collected Your Personal
              Information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, your employer, or your health plan
              carrier, or otherwise perform our Services.
            </li>
            <li class="list-disc">
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li class="list-disc">
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li class="list-disc">
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li class="list-disc">
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 seq.).
            </li>
            <li class="list-disc">
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the
              research's achievement, if you previously provided informed
              consent.
            </li>
            <li class="list-disc">
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on your relationship with us.
            </li>
            <li class="list-disc">Comply with a legal obligation.</li>
            <li class="list-disc">
              Make other internal and lawful uses of that information that are
              compatible with the context in which you provided it.
            </li>
          </ul>
          <p class="my-3">
            Additionally, Accolade may deny your deletion request if the
            information sought to be deleted is protected healthcare information
            disclosed to us as part of your participation in an
            employer-sponsored group health plan and/or your health plan carrier
            through which you have purchased a health plan or other benefits.
          </p>
          <p class="italic">
            Exercising Your Access, Data Portability, and Deletion Rights
          </p>
          <p class="my-3">
            To exercise the access, data portability, and deletion rights
            described above, please submit a verifiable consumer request to
            Accolade by either:
          </p>
          <ul class='ml-10'>
            <li class="list-disc">
              Visiting the Data Subjects Rights Portal located{' '}
              <a class="text-blue-brand underline" href="https://privacyportal-cdn.onetrust.com/dsarwebform/c4f1baaa-e268-4529-bd6b-c6677c765a0f/3cc3930e-3c94-41cb-8d2d-e5b4e81a1e8f.html">
                here
              </a>
              ; or{' '}
            </li>
            <li class="list-disc">
              Calling the following toll-free number at 1-888-914-9661, and
              using PIN 494 202 to identify that you are calling with respect to
              Accolade.
            </li>
          </ul>
          <p class="font-bold my-3">
            We ask that you not provide us with any medical or protected health
            information through the above communications.
          </p>
          <p class="my-3">
            Only you or a person registered with the California Secretary of
            State that you authorize to act on your behalf, may make a
            verifiable consumer request related to Your Personal Information.
            You may also make a verifiable consumer request on behalf of your
            minor child.
          </p>
          <p class="my-3">
            You may only make a verifiable consumer request for access or data
            portability two times within a 12-month period. The verifiable
            consumer request must:
          </p>
          <ul class='ml-10'>
            <li class="list-disc">
              Provide sufficient information that allows Accolade to reasonably
              verify you are the person about whom Accolade collected personal
              information or an authorized representative; and
            </li>
            <li class="list-disc">
              Describe your request with sufficient detail that allows Accolade
              to properly understand, evaluate, and respond to it.
            </li>
          </ul>
          <p class="my-3">
            Accolade cannot respond to your request or provide you with Your
            Personal Information if Accolade cannot verify your identity or
            authority to make the request and confirm the Personal Information
            relates to you. Making a verifiable consumer request does not
            require you to create an account with us. We will only use Personal
            Information provided in a verifiable consumer request to verify the
            requestor's identity or authority to make the request. Any
            disclosures provided pursuant to the CCPA will only cover the
            12-month period preceding the receipt of the verifiable consumer
            request.{' '}
          </p>
          <p class="my-3">
            Accolade will respond to a verifiable consumer request within 45
            days of its receipt, or notify you that Accolade requires more time
            to respond and the reason for the extended response time. Accolade
            will deliver its written response by mail or electronically, at your
            option.{' '}
          </p>
          <p class="my-3">
            To the extent that your verifiable consumer request is excessive,
            repetitive, or manifestly unfounded, Accolade may charge a
            reasonable fee to respond to such request.{' '}
          </p>
          <p class="my-3">
            Accolade will not discriminate against you for exercising any of
            your CCPA rights. Unless permitted by the CCPA, Accolade will not:
          </p>
          <ul class='ml-10'>
            <li class="list-disc">Deny you goods or services.</li>
            <li class="list-disc">
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </li>
            <li class="list-disc">
              Provide you a different level or quality of goods or services.
            </li>
            <li class="list-disc">
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </li>
          </ul>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">CONTACT US</h2>
          <p class="my-3">
            If you have any questions, comments or concerns about the Accolade
            Digital Tools or these Terms, please contact us using the Accolade
            Digital Tools portal or as follows:
          </p>
          <table class="table-auto my-3">
            <tbody>
              <tr>
                <td class="px-4 border py-2">
                  FOR GENERAL INQUIRIES:
                  <br />
                  <a class="text-blue-brand underline" href="mailto:Legal@Accolade.com">Legal@Accolade.com</a>
                </td>
                <td class="px-4 border py-2">
                  MAIL:
                  <br />
                  LEGAL DEPARTMENT ACCOLADE, INC.
                  <br />
                  660 WEST GERMANTOWN PIKE, SUITE 500 PLYMOUTH MEETING, PA.
                  19462
                </td>
              </tr>
              <tr>
                <td class="px-4 border py-2">
                  FOR PRIVACY INQUIRIES:
                  <br />
                  <a class="text-blue-brand underline" href="mailto:Privacy@Accolade.com">Privacy@Accolade.com</a>
                </td>
                <td class="px-4 border py-2">
                  MAIL:
                  <br />
                  CHIEF PRIVACY OFFICER ACCOLADE, INC.
                  <br />
                  660 WEST GERMANTOWN PIKE, SUITE 500
                  <br />
                  PLYMOUTH MEETING, PA. 19462
                </td>
              </tr>
              <tr>
                <td class="px-4 border py-2">
                  FOR SECURITY CONCERNS:
                  <br />
                  <a class="text-blue-brand underline" href="mailto:Security@Accolade.com">
                    Security@Accolade.com
                  </a>
                </td>
                <td class="px-4 border py-2">
                  MAIL:
                  <br />
                  CHIEF INFORMATION SECURITY OFFICER ACCOLADE, INC.
                  <br />
                  1201 THIRD AVE, SUITE 1700
                  <br />
                  SEATTLE, WA 98101
                </td>
              </tr>
            </tbody>
          </table>
          <p class="font-bold">
            NO REQUEST TO EXERCISE A CONSUMER’S RIGHT UNDER THE CCPA SHOULD BE
            SENT TO THE ABOVE POINTS OF CONTACT. ALL CONSUMER REQUESTS UNDER THE
            CCPA MUST BE SENT TO THE FOLLOWING: (1) Data Subjects Rights Portal
            located{' '}
            <a class="text-blue-brand underline" href="https://privacyportal-cdn.onetrust.com/dsarwebform/c4f1baaa-e268-4529-bd6b-c6677c765a0f/3cc3930e-3c94-41cb-8d2d-e5b4e81a1e8f.html">
              here
            </a>
            ; or (2) Calling the following toll-free number, at 1-888-914-9661,
            and using PIN 494 202 to identify that you are calling with respect
            to Accolade{' '}
          </p>
          <p class="my-3">
            While the Accolade Digital Tools provides for secure electronic
            messaging, email that you send to Accolade through the above email
            addresses, like most, if not all, non-encrypted Internet e-mail
            communications, may be accessed or intercepted and viewed by other
            Internet users, without your knowledge and permission, while in
            transit to us. We do not control the transmission of information to
            these email addresses through the Internet.
          </p>
          <p class="my-3">
            For that reason, to protect your privacy, please do not use email to
            communicate information to us that you consider confidential. If you
            wish, you may contact your Accolade Health Assistant through the
            Accolade Digital Tools securely or use the telephone numbers that
            appears on your ID card.
          </p>
          <h2 class="text-3xl font-semibold py-2 text-blue-brand">Changes to this Privacy Notice</h2>
          <p class="my-3">
            We reserve the right to amend this Privacy Notice at our discretion and at any time. When we make changes to this Privacy Notice, we will notify you by email or through a notice on our website homepage.
          </p>
        </section>
      </div>
      <footer class="flex w-full flex-col sm:flex sm:flex-row sm:justify-between items-center bg-DarkBlue text-white py-8 px-8">
        <nav>
          <ul class="flex text-sm">
            <li class="px-2">
              <a class="text-blue-brand underline" href="https://www.accolade.com/blog-listings/">Blog</a>
            </li>
            <li class="px-2">
              <a class="text-blue-brand underline" href="https://www.accolade.com/contact/">Contact</a>
            </li>
            <li class="px-2">
              <a class="text-blue-brand underline" href="https://www.accolade.com/legal/policies/privacy">
                Privacy Policy
              </a>
            </li>
            <li class="px-2">
              <a class="text-blue-brand underline" href="https://www.accolade.com/legal/terms/">Terms of Use</a>
            </li>
            <li class="px-2">
              <a class="text-blue-brand underline" href="https://www.accolade.com/careers/">Careers</a>
            </li>
          </ul>
        </nav>
        <nav class="text-right">
          <ul class="flex justify-end">
            <li class="px-2">
              <a class="text-blue-brand underline"
                href="https://www.linkedin.com/company/accolade-inc-/"
                target="_blank"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li class="px-2">
              <a class="text-blue-brand underline" href="https://www.facebook.com/AccoladeInc/" target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li class="px-2">
              <a class="text-blue-brand underline" href="https://twitter.com/accolade" target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="px-2">
              <a class="text-blue-brand underline"
                href="https://www.instagram.com/we_are_accolade/?hl=en"
                target="_blank"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
          <p class="pt-2 text-xs">©2020 Accolade all rights reserved.</p>
        </nav>
      </footer>
    </div>
  </Layout>
)
